import { SeoTagset } from "containers/SeoTagsets/seo-tagsets";

export enum CategoryKind {
  BestRecipes = "BestRecipes",
  Cuisine = "Cuisine",
  Diet = "Diet",
  Meal = "Meal",
  Occasion = "Occasion",
}

export type Category = {
  id: number;
  name: string;
  description: string;
  slug: string;
  isVisible: boolean;
  isFeatured: boolean;
  imageUrl: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  position: number;
  showInShortcuts: boolean;
  shortcutPosition: number;
  isAvailableInFilters: boolean;
  parent: any;
  children: any[];
  color: string;
  kind: CategoryKind;
  seoTagset: SeoTagset;
};
